import React from "react";
import {
  Container,
  Layout,
  UsedBikeSingleButtons,
  UsedBikeSingleGallery,
  UsedBikeSinglePrices,
  UsedBikeSpecs,
} from "components";
import { FeaturedPosts, Hero } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { UsedBikeIdQuery } from "codegen";
import "./used-single.scss";
import { PageProps, graphql } from "gatsby";

interface CPTPageContext {
  id: string;
  title: string;
  cptType: string;
}

const UsedBikeSingle = ({ data: { usedBike } }: PageProps<UsedBikeIdQuery, CPTPageContext>): JSX.Element => {
  const { title, acfLayout, content, seo, featuredImage } = usedBike;

  return (
    <Layout pageTitle={title} seo={seo}>
      <>
        <Hero
          data={{
            content: `<p className="typography--h1">Used Bikes</p>`,
          }}
          location={seo.breadcrumbs}
        />
        <Container className="used-single-header">
          <UsedBikeSingleGallery data={usedBike} />
          <div>
            <UsedBikeSinglePrices data={usedBike} />
            <UsedBikeSingleButtons />
            <p
              className="used-single-sosumi"
              dangerouslySetInnerHTML={{ __html: usedBike?.postTypeUsedBike?.sosumi }}
            />
          </div>
        </Container>
        <UsedBikeSpecs data={usedBike} />
        <FeaturedPosts data={usedBike?.postTypeUsedBike?.relatedBikes} />
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "usedmotorcycles" })(UsedBikeSingle);

export const CPTQueryById = graphql`
  query UsedBikeId($id: String!, $previousPostId: String, $nextPostId: String) {
    # selecting the current page by id
    usedBike: wpUsedMotorcycle(id: { eq: $id }) {
      ...UsedMotorcyclesFields
      postTypeUsedBike {
        rideAwayPrice
        perWeekPrice
        financeTotalCost
        interestRate
        comparisonRate
        sosumi
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1000)
            }
          }
        }
        specs {
          specType
          specDetail
        }
        relatedBikes {
          fieldGroupName
          feedTitle
          feedType
          __typename
          # To save some headaches for someone later. This query will fail during build if a selected/related post is DRAFTED or TRASHED.
          # Check the failing page for either of those
          feedItems {
            ...NewMotorcyclesFields
            ...UsedMotorcyclesFields
          }
        }
      }
    }
    previous: wpUsedMotorcycle(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpUsedMotorcycle(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
